<template>
  <div class="caricature">
    <div class="mainBox">
      <div class="head">
        <div class="navContentBox">
          <div class="navContent">
            <van-tabs
              class="navTabs oepnX"
              line-width="0"
              line-height="0"
              v-model="activeIndex"
              @change="clickTag"
              background="rgba(0,0,0,0)"
              v-if="navList && navList.length"
            >
              <van-tab v-for="(item, index) in navList" :key="index">
                <template #title>
                  <span
                    class="navTabTitle"
                    :class="{ navTabTitleActive: activeIndex == index }"
                  >
                    {{ item.subModuleName }}
                  </span>
                </template>
              </van-tab>
            </van-tabs>
            <!-- <div class="navRight" @click="myChannelShow = true">
              <img src="@/assets/png/tabSetting.png" />
            </div> -->
          </div>
        </div>

        <div class="swiperBox" v-if="bannerList && bannerList.length">
          <van-swipe class="swipe" :autoplay="3000" indicator-color="#FECD55">
            <van-swipe-item
              v-for="(item, index) in bannerList"
              :key="index"
              @click="goAdv(item)"
            >
              <ImgDecypt :src="item.cover" :key="item.cover" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>

      <div class="container">
        <div class="notice" v-if="marqueeText">
          <img src="@/assets/png/notice.png" />
          <van-notice-bar
            class="noticeBar"
            speed="20"
            color="rgb(0,0,0)"
            background="rgba(0,0,0,0)"
            :text="marqueeText"
          />
        </div>
        <div
          class="barTopic"
          v-if="
            jingangArea && this.navList[this.activeIndex]['keyName'] !== '热更'
          "
        >
          <div
            class="barTopicItem"
            v-for="topicItem in jingangArea"
            :key="topicItem.id"
            @click="goAdv(topicItem)"
          >
            <ImgDecypt
              class="topicImg"
              :src="topicItem.img"
              :key="topicItem.img"
            />
            <p>
              <span>{{ topicItem.name }}</span>
            </p>
          </div>
        </div>
        <div class="jgLine"></div>
      </div>
      <van-sticky offset-top="6rem" @change="changeRefreshDisable"></van-sticky>
      <div class="content">
        <van-swipe
          ref="slideSwipe"
          class="slideSwipe"
          :show-indicators="false"
          indicator-color="white"
          :initialSwipe="activeIndex"
          @change="pageSlideSwipe"
        >
          <van-swipe-item
            class="slideSwipeItem"
            v-for="(item, index) in navList"
            :key="index"
          >
            <div ref="swipeContent">
              <div class="videoContent">
                <PullRefresh
                  :disabled="isRefreshDisable"
                  v-if="!isLoading"
                  :loading="loading"
                  :refreshing="refreshing"
                  :finished="finished"
                  @onLoad="onLoad"
                  @onRefresh="onRefresh"
                  :isNoData="isNoData"
                  :error="error"
                  :is-higeht-max="true"
                  :hasAdvPagination="true"
                >
                  <div v-for="(item, index) in videoList" :key="index">
                    <VerticleThree
                      v-if="item['direction'] == 0 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                      :num="3"
                    ></VerticleThree>
                    <VerticleThree
                      v-if="item['direction'] == 1 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                      :num="6"
                    ></VerticleThree>
                    <VerticleTwo
                      v-if="item['direction'] == 2 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                    ></VerticleTwo>
                    <TransverselyTwo
                      v-if="item['direction'] == 3 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                    ></TransverselyTwo>
                    <TransverselyFive
                      v-if="item['direction'] == 4 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                    ></TransverselyFive>
                    <TransverselyTwo
                      v-if="item['direction'] == 5 && item['MediaList']"
                      :videoInfoData="item"
                      :tabNmae="tabNmae"
                      :num="6"
                    >
                    </TransverselyTwo>
                    <div
                      class="advertising"
                      v-if="advList.length > 0 && item['MediaList']"
                      @click="goAdv(advList[index % advList.length])"
                    >
                      <ImgDecypt
                        class="advImg"
                        :src="advList[index % advList.length].cover"
                      />
                    </div>
                  </div>
                </PullRefresh>
                <Loading v-else />
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <div style="width: 100%; height: 300px"></div>
    </div>

    <!-- 9 宫格广告 -->
    <AdvSudoku
      :advList="advSudokuList"
      :show="advSudokuShow"
      @close="advSudokuClose"
    />

    <!-- 系统公告 -->
    <AnnouncementText
      :advList="announcementTextList"
      :show="announcementTextShow"
      @close="announcementTextClose"
    />

    <!-- 图片广告 -->
    <AdvPicture
      :advList="advPictureList"
      :show="advPictureShow"
      @close="advPictureClose"
    />

    <FloatingAdv />
  </div>
</template>

<script>
import AdvSudoku from "@/components/AdvSudoku";
import AdvPicture from "@/components/AdvPicture";
import { queryTopicList } from "@/api/video";
import Loading from "@/components/Loading";
import VerticleThree from "@/components/VideoList/VerticleThree";
import VerticleTwo from "@/components/VideoList/VerticleTwo";
import TransverselyTwo from "@/components/VideoList/TransverselyTwo";
import TransverselyFive from "@/components/VideoList/TransverselyFive";
import PullRefresh from "@/components/PullRefresh";
import ImgDecypt from "@/components/ImgDecypt";
import FloatingAdv from "@/components/FloatingAdv";
import { moduleList, moduleListDetails, moduleListMore } from "@/api/home";

import { getSessionItem, setSessionItem } from "@/utils/longStorage";

import {
  AdType,
  getAdItem,
  jumpAdv,
  getMarquee,
  MarqueeType,
  getOfficialConfigApps,
  getAnnouncementText,
} from "@/utils/getConfig";
import AnnouncementText from "@/components/AnnouncementText";
export default {
  name: "caricature",
  props: {
    Modulelist: {
      type: Array,
    },
  },
  components: {
    PullRefresh,
    Loading,
    VerticleThree,
    VerticleTwo,
    TransverselyTwo,
    TransverselyFive,
    ImgDecypt,
    AnnouncementText,
    AdvSudoku,
    AdvPicture,
    FloatingAdv,
  },
  data() {
    return {
      tabNmae: "动漫",
      activeIndex: 1,
      activeName: "精选",
      pageNumber: 1,
      pageSize: 50,
      // navList: this.Modulelist,
      navList: [
        {
          subModuleName: "热更",
          keyName: "热更",
          pageNumber: 1,
          pageSize: 50,
          isNoData: false,
          finished: false,
          height: 100,
          videoList: [],
        },
        {
          subModuleName: "精选",
          keyName: "精选",
          pageNumber: 1,
          pageSize: 50,
          isNoData: false,
          finished: false,
          height: 100,
          videoList: [],
        },
        ...this.Modulelist,
        {
          subModuleName: "非H",
          keyName: "禁播",
          pageNumber: 1,
          pageSize: 50,
          isNoData: false,
          finished: false,
          height: 100,
          videoList: [],
        },
        {
          subModuleName: "真人",
          keyName: "真人",
          pageNumber: 1,
          pageSize: 50,
          isNoData: false,
          finished: false,
          height: 100,
          videoList: [],
        },
      ],
      videoList: [],
      isNoData: false,
      finished: false,
      isLoading: true,
      loading: true,
      suctionCapState: false,
      refreshing: false,
      marqueeText: null,
      error: false,
      myChannelShow: false,
      bannerList: [],
      advSudokuShow: false, //九宫格广告弹窗
      advSudokuList: [], //九宫格广告数据
      announcementTextShow: false, //公告弹窗
      announcementTextList: [], //公告数据
      advPictureShow: false, //图片广告弹窗
      advPictureList: [], //图片广告数据
      listAdv: [], //首页列表广告
      advList: [], //专栏广告
      isRefreshDisable: false,
      jingangArea: [],
      videoNewList: [],
    };
  },
  computed: {},
  activated() {
    // this.swipeTo(this.activeIndex);
  },
  created() {
    this.bannerList = getAdItem(AdType.COMMUNITY_RECOMMEND);
    this.listAdv = getAdItem(AdType.COLUMN_ADV);
    let appConfig = JSON.parse(getSessionItem("appConfig"));
    this.jingangArea = appConfig.jingangArea.list || [];
  },
  mounted() {
    this.advSudokuList = getOfficialConfigApps();
    this.announcementTextList = getAnnouncementText();
    this.advPictureList = getAdItem(AdType.FREE_VIDEO).reverse();
    this.advList = getAdItem(AdType.COLUMN_ADV);
    this.marqueeText = getMarquee(MarqueeType.community);
    if (
      this.advSudokuList.length > 0 &&
      getSessionItem("advSudokuPop") != "1"
    ) {
      setSessionItem("advSudokuPop", "1");
      this.advSudokuShow = true;
    } else if (
      this.announcementTextList.length > 0 &&
      getSessionItem("announcementTextPop") != "1"
    ) {
      setSessionItem("announcementTextPop", "1");
      this.announcementTextShow = true;
    } else if (
      this.advPictureList.length > 0 &&
      getSessionItem("advPicturePop") != "1"
    ) {
      setSessionItem("advPicturePop", "1");
      this.advPictureShow = true;
    }
    this.reset();
  },
  methods: {
    async queryTopicNewListInfo(type) {
      let req = {
        pageNumber: this.navList[this.activeIndex]["pageNumber"],
        pageSize: this.navList[this.activeIndex]["pageSize"],
        topicMediaSize: 6,
        zone: this.navList[this.activeIndex]["keyName"],
      };
      let res = await this.$Api(queryTopicList, req);
      console.log(res, "123123");
      this.loading = false;
      this.refreshing = false;
      this.isLoading = false;
      const { list } = res.data;
      if (type == "refresh") {
        this.videoList = list;
      } else {
        this.videoList = this.videoList.concat(list);
      }
      if (this.pageNumber === 1 && this.videoList.length === 0) {
        this.isNoData = true;
      }
      if (!res.data.hasNext) {
        this.finished = true;
      }
    },
    advPictureClose() {
      /**
       * 图片广告
       */
      // this.advPictureList.pop();
      // if (this.advPictureList.length <= 0) {
      this.advPictureShow = false;
      // }
    },
    announcementTextClose() {
      /**
       * 公告弹窗关闭
       */
      this.announcementTextShow = false;

      if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    advSudokuClose() {
      /**
       * 九宫格弹窗关闭
       */
      this.advSudokuShow = false;

      if (
        this.announcementTextList.length > 0 &&
        getSessionItem("announcementTextPop") != "1"
      ) {
        setSessionItem("announcementTextPop", "1");
        this.announcementTextShow = true;
      } else if (
        this.advPictureList.length > 0 &&
        getSessionItem("advPicturePop") != "1"
      ) {
        setSessionItem("advPicturePop", "1");
        this.advPictureShow = true;
      }
    },
    goAdv(item) {
      jumpAdv(item);
    },
    clickTag(index) {
      this.activeIndex = index;
      this.pageNumber = 1;
      this.reset();
      // this.myChannelShow = false;
      // this.swipeTo(index);
    },
    reset() {
      this.loading = true;
      this.isLoading = true;
      this.finished = false;
      this.isNoData = false;
      this.videoList = [];
      // const getIndex = [0, 1, 7, 8]; // 0热更, 1精选, 3禁播 4真人
      const getIndex = this.navList
        .map((item, index) => {
          return ["热更", "精选", "非H", "真人"].includes(item.subModuleName)
            ? index
            : -1;
        })
        .filter((index) => index !== -1);
      if (getIndex.includes(this.activeIndex)) {
        this.queryTopicNewListInfo("refresh");
      } else {
        this.queryTopicListInfo("refresh");
      }
      this.activeName = this.navList[this.activeIndex].keyName;
      console.log(this.activeName, "动漫+++");
    },
    onRefresh() {
      this.refreshing = true;
      this.pageNumber = 1;
      this.reset();
    },
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.reset();
    },
    async queryTopicListInfo(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        subModuleId: this.navList[this.activeIndex].id,
      };

      try {
        let res = await this.$Api(moduleListDetails, req);
        this.loading = false;
        this.refreshing = false;
        this.isLoading = false;
        if (type == "refresh") {
          this.videoList = res.data.list;
        } else {
          this.videoList = this.videoList.concat(res.data.list);
        }

        if (this.pageNumber === 1 && this.videoList.length === 0) {
          this.isNoData = true;
        }
        if (!res.data.hasNext) {
          this.finished = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    suctionCap() {
      this.suctionCapState = !this.suctionCapState;
    },
    // 切换下拉禁用状态
    changeRefreshDisable() {
      this.isRefreshDisable = !this.isRefreshDisable;
    },
    pageSlideSwipe(index) {
      /**
       * 页面滑动切换
       */
      this.activeIndex = index;
      this.pageNumber = 1;
      this.reset();
      // this.swipeTo(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.caricature {
  height: 100%;
  background: rgba(255, 255, 255, 1);
  // overflow-y: auto;
  .mainBox {
    //height: calc(100vh - 66px);
    //overflow-x: auto;
    //-webkit-overflow-scrolling: touch;
  }

  .loading {
    height: 100%;
  }

  .head {
    .suctionCap {
      // position: absolute;
      // width: 100%;
      // height: 88px;
      // background-image: url("~@/assets/png/headBg.png");
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      // background-color: rgb(255, 255, 255);
      // position: fixed;
      // top: 0;
      // z-index: 20;
    }

    // .navContentSuctionCap {
    //   // position: absolute;
    //   // top: 0;
    //   width: calc(100% - 44px);
    //   .navTabs {
    //     .navTabTitle {
    //       font-size: 14px;
    //       color:#999999;
    //       font-weight: 100;
    //     }

    //     .navTabTitleActive {
    //       color: #000 !important;
    //       font-weight: 900;
    //     }

    //     /deep/ .van-tabs__nav--complete {
    //       padding-left: 12px;
    //     }

    //     /deep/ .van-tabs__wrap--scrollable .van-tab {
    //       padding: 0;
    //       padding-right: 21px;
    //     }

    //     /deep/ .van-tabs__wrap {
    //       height: 44px;
    //       .van-tabs__line {
    //         background: transparent;
    //         background-image: url("~@/assets/png/homeTags.png") !important;
    //         background-size: 100% 100%;
    //         background-repeat: no-repeat;
    //         width: 22px !important;
    //         height: 3px !important;
    //         bottom: 20px;
    //       }
    //     }

    //     // /deep/ .van-tabs__nav--line {
    //     //   padding-bottom: 0;
    //     // }
    //   }

    //   .navRight {
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     height: 44px;
    //     width: 44px;
    //     //background: rgb(43,39,72);

    //     img {
    //       width: 19px;
    //       height: 16px;
    //     }
    //   }
    // }

    .navContentBox {
      // position: fixed;
      // top: 0;
      width: 100%;
      height: 32px;
      z-index: 20;
    }

    .navContent {
      position: absolute;
      z-index: 20;
      height: 42px;
      background: #ffffff;
      width: 100%;
      .navTabs {
        padding: 10px 16px;
        .navTabTitle {
          font-size: 14px;
          color: #333333;
          font-weight: 400;
        }

        .navTabTitleActive {
          font-weight: 600;
          font-size: 20px;
        }

        /deep/ .van-tabs__nav--complete {
          // padding: 0 !important;
        }

        /deep/ .van-tabs__wrap--scrollable .van-tab {
          padding: 0;
          // padding-right: 21px;
        }
        /deep/ .van-tabs__wrap {
          height: 44px;
          .van-tabs__line {
            background: transparent;
            background-image: url("~@/assets/png/homeTags.png") !important;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            width: 22px !important;
            height: 3px !important;
            bottom: 20px;
          }
          .van-tabs__nav--line {
            height: 55%;
            padding-bottom: 25px;
          }
        }
        /deep/ .van-tabs__nav {
          justify-content: flex-start;
        }
        /deep/ .van-tab {
          flex: none;
          margin-right: 12px;
        }

        // /deep/ .van-tabs__nav--line {
        //   padding-bottom: 0;
        // }
      }

      .navRight {
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 44px;
        width: 44px;
        //background: rgb(43,39,72);

        img {
          width: 19px;
          height: 16px;
        }
      }
    }

    .searchContentSuctionCap {
      // position: absolute;
      // z-index: 20;
      height: 30px;
      width: 100vw;
      // margin-top: 53px;

      .searchBar {
        display: flex;

        .search {
          width: 267px;
          height: 30px;
          border-radius: 15px;
          background: rgba(0, 0, 0, 0.15);
          font-size: 12px;
          display: flex;
          align-items: center;
          margin: 0 12px;
          color: rgba(0, 0, 0, 0.6);

          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
          }
        }

        span {
          margin-left: 7px;
        }
      }

      .iconBlock {
        margin-right: 12px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .searchContent {
      // position: absolute;
      // z-index: 20;
      height: 30px;
      width: 100vw;
      // margin-top: 53px;

      .searchBar {
        display: flex;

        .search {
          width: 267px;
          height: 30px;
          border-radius: 15px;
          background: rgba(0, 0, 0, 0.15);
          font-size: 12px;
          display: flex;
          align-items: center;
          margin: 0 12px;
          color: rgb(204, 204, 204);

          img {
            width: 17px;
            height: 17px;
            margin-left: 12px;
          }
        }

        span {
          margin-left: 7px;
        }
      }

      // .swipe {
      //     height: 360px;
      // }

      .iconBlock {
        margin-right: 12px;

        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .swiperBox {
      padding: 12px 14px;
      border-radius: 8px;
    }
    .swipe {
      height: 143px;
      border-radius: 8px;

      .van-swipe-item {
        color: #fff;
        font-size: 20px;
        line-height: 150px;
        text-align: center;
        background-color: rgb(43, 39, 72);
      }

      /deep/ .van-swipe__indicators {
        //left: auto;
        //right: 0;
        align-items: center;
      }

      /deep/ .van-swipe__indicator {
        width: 6px;
        height: 6px;
        background-color: rgba(251, 209, 61, 0.3);
      }

      /deep/ .van-swipe__indicator--active {
        width: 9px;
        height: 9px;
        background-color: rgba(251, 209, 61, 1) !important;
      }
      /deep/ img {
        object-fit: fill !important;
      }
    }
  }

  .container {
    // padding: 0 16px;
    .notice {
      margin-top: 12px;
      background: rgba(243, 243, 243, 1);
      color: #999999;
      height: 25px;
      border-radius: 6px;
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-left: 8px;
      }

      /deep/ .van-notice-bar {
        padding: 0;
        font-size: 12px;
      }

      .noticeBar {
        width: 100%;
        height: 100%;
        color: #999999 !important;
        padding: 0 13px 0 4.6px;
      }
    }
    .barTopic {
      width: 100%;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      // justify-content: space-between;
      padding: 12px 0;
      font-size: 10px;
      color: #000000;
      text-align: center;
      // border-bottom: solid rgb(230, 230, 230) 1px;
      box-sizing: border-box;
      overscroll-behavior: contain;
      //   position: absolute;
      //   top: 37px;
      //   z-index: 10;
      .barTopicItem {
        // width: 86px;
        flex: none;
        display: flex;
        //flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-left: 6px;
        p {
          color: rgba(51, 51, 51, 1);
          font-size: 14px;
          white-space: nowrap;
          margin: 0 6px;
          span {
            padding-right: 12px;
            border-right: 2px solid rgba(217, 217, 217, 1);
            display: inline-block;
            line-height: 1;
          }
        }
      }

      .barTopicItem:first-child {
        padding-left: 16px;
      }
      .barTopicItem:last-child {
        padding-right: 30px;
        span {
          padding-right: 12px;
          border: none;
        }
      }

      .topicImg {
        width: 24px;
        height: 24px;
        margin-bottom: 2px;
      }

      img {
        width: 42px;
        height: 42px;
      }

      // span {
      //     margin-top: 4px;
      // }
    }
  }

  .content {
    padding: 0 12px;
    // position: relative;

    .videoContent {
      margin-top: 6px;

      .advertising {
        // border-bottom: solid 1px rgb(230, 230, 230);
        padding-bottom: 12px;
        margin-top: 12px;
        border-radius: 8px;
        /deep/ .vanImage {
          border-radius: 8px;
        }
        /deep/ .van-image__img {
          border-radius: 8px;
        }
        .advertisingImg {
          width: 351px;
          height: 73.13px;
          background: rgb(43, 39, 72);
          border-radius: 6px;
        }
      }
    }
  }
}

.myChannel {
  .homeHeadBar {
    //border: solid 1px red;
    color: #000000;
    display: flex;
    height: 44px;
    align-items: center;
    border-bottom: solid 1px rgb(230, 230, 230);

    .leftIcon {
      padding: 0 12px;

      img {
        width: 17px;
        height: 17px;
      }
    }

    .headTitle {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 24px;
      font-weight: bold;
      font-size: 18px;
    }
  }

  .contentBar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 100px;
    padding: 0 88px;

    //padding: 0 32px;

    .blockItem {
      //width: 50%;
      //padding-left: 24px;
      //display: flex;
      //justify-content: center;
      margin-top: 30px;

      img {
        height: 88px;
        height: 88px;
      }
    }
  }
}

.adImgBox {
  width: 100%;
  height: 88px;

  /deep/ img {
    object-fit: fill !important;
  }
}

.isFirst {
  margin-top: 85px !important;
}
.jgLine {
  margin: 0 16px;
  box-sizing: border-box;
  // width: 100%;
  height: 1px;
  background: #f2f2f2;
}
</style>
