<template>
  <div class="home">
    <div class="tab-container">
     <div v-if="showTab" class="tab-header">
        <div class="tab-left">
          <div
            class="tab-item"
            v-for="(item, index) in tabs"
            :key="index"
            :class="{ active: index === currentTab }"
            @click="changeModule(index)"
          >
<!--            {{ item.title }}-->
           <img :src="currentTab === index ? item.activeImg : item.inactiveImg" />
          </div>
        </div>
        <div class="tab-right">
          <div class="ranking" @click="$router.push('/searchPage')">
            <img src="@/assets/png/ranking.png" />
          </div>
          <div class="ranking" @click="$router.push('historyRecord')">
            <img src="@/assets/png/welfare.png" />
          </div>
          <div class="ranking" @click="$router.push('/categoriesPage')">
            <img src="@/assets/png/classify.png" />
          </div>
        </div>
      </div>
      <div class="tab-content">
        <transition name="fade">
          <component
            v-if="ModuleLists.length"
            :is="currentTab ? 'caricature' : 'animation'"
            :Modulelist="ModuleLists"
            :title="moduleName"
          ></component>
        </transition>
      </div>
    </div>
  </div>
</template>
<script>
import animation from "./Tab/animation";
import caricature from "./Tab/caricature";
import { moduleList } from "@/api/home";
import { Toast } from "vant";

export default {
  name: "Home",
  components: {
    animation,
    caricature,
  },
  data() {
    return {
      currentTab: 0,
      moduleName: "动漫",
      ModuleLists: [],
      tabs: [
        {
          title: "动漫",
          activeImg: require("@/assets/png/animationHomeBg.png"),
          inactiveImg: require("@/assets/png/animationHomeInBg.png")
        },
       {
        title: "漫画",
        activeImg: require("@/assets/png/caricatureHomeBg.png"),
        inactiveImg: require("@/assets/png/caricatureInBg.png")
       },
      ],
     showTab: true,
     lastScrollY: 0 // 记录滚动位置
    };
  },
  created() {},
  methods: {
    changeModule(index) {
     // console.log("点击切换",index, this.currentTab);
     if (this.ModuleLists.length) {
        this.ModuleLists = [];
        this.currentTab = index;
        this.moduleName = index ? "漫画" : "动漫";
        this.queryTopicListInfo();
      } else {
        Toast("数据没加载完成请等待！");
      }
    },
    async queryTopicListInfo() {
      let req = {
        moduleName: this.moduleName,
      };
      try {
        let res = await this.$Api(moduleList, req);
        const { data } = res;
        for (const item of data) {
         item.keyName = item.subModuleName
        }
       // console.log("Home", this.currentTab, this.moduleName);
        this.ModuleLists = data;
      } catch (error) {
        console.log(error);
      }
    },
    handleScroll(e) {
     const currentScrollY = e.target.scrollTop;
     // this.showTab = currentScrollY < this.lastScrollY;
     this.lastScrollY = currentScrollY;
     if (this.lastScrollY > 1200 && !this.$store.getters.getShowBackToTop) {
      this.$store.dispatch('movies/setShowBackToTop', true)
     } else if (this.$store.getters.getShowBackToTop && this.lastScrollY < 1200) {
      this.$store.commit('movies/setShowBackToTop', false)
     }
     // console.log(this.showTab, '111111111', this.lastScrollY);
    },
  },
 mounted() {
  this.queryTopicListInfo();
  this.$nextTick(() => {
   const tabContent = document.querySelector('.tab-content');
   if (tabContent) {
    tabContent.addEventListener('scroll', this.handleScroll);
   }
  });
 },
 beforeDestroy() {
  const tabContent = document.querySelector('.tab-content');
  if (tabContent) {
   tabContent.removeEventListener('scroll', this.handleScroll);
  }
 },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  z-index: -1;
  .tab-container {
    height: 100%;
    background: #ffffff;
  }
  .tab-header {
    padding: 10px 16px 5px;
    height: 39px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    border: 1px solid #ffffff;
    box-sizing: border-box;
    .tab-left {
      //width: 164px;
      height: 36px;
      //background: #f1f1f1;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .tab-right {
      display: flex;
      .ranking {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;

        img {
          width: 30px;
          height: 30px;
        }
      }
      .ranking:last-child {
        margin-right: 0;
      }
    }
  }
  .tab-item {
    cursor: pointer;
    color: #000000;
    width: 70px;
    //height: 32px;
    //background: #f1f1f1;
    //border-radius: 16px;
    //text-align: center;
    //line-height: 32px;
    //font-size: 12px;
    //font-weight: 400;
   vertical-align: top;
   img {
    width: 64px;
    height: 42px;
   }
  }

  //.active {
  //  color: #000000;
  //  font-weight: bold;
  //  background: #94D6DA;
  //}

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .tab-content {
   overflow-x: auto;
   -webkit-overflow-scrolling: touch;
   height: calc(100vh - 80px);
  }
}
</style>
