<template>
  <div class="transverselyFive">
    <div
      class="videoItem"
      v-if="videoInfoData && videoInfoData['MediaList'].length > 0"
    >
      <div class="titleBar">
        <div>
          <p class="titleName">{{ videoInfoData.name }}</p>
          <p class="titleDetails">{{ videoInfoData.desc }}</p>
        </div>
        <div class="moreRight" @click="moreMore">
          <!-- <span class="moreRightTitle">更多</span> -->
          <img src="@/assets/png/moreRight.png" />
        </div>
      </div>
      <div class="videoInfo">
        <div
          class="videoInfoItemBag"
          v-if="videoInfoData['MediaList'][0] != null"
        >
          <div
            class="videoLabel beanLabel"
            v-if="videoInfoData['MediaList'][0].permission == 1"
          >
            <svg-icon class="Golden" iconClass="Golden"></svg-icon>
            {{
              (videoInfoData["MediaList"][0].kind == 4 ||
                videoInfoData["MediaList"][0].kind == 1) &&
              videoInfoData["MediaList"][0].price > 0
                ? videoInfoData["MediaList"][0].price
                : ""
            }}
          </div>

          <div
            class="videoLabel freeLabel"
            v-if="videoInfoData['MediaList'][0].permission == 2"
          >
            <span>免费</span>
          </div>
          <div class="previewImg" @click="jump(videoInfoData['MediaList'][0])">
            <ImgDecypt
              :src="videoInfoData['MediaList'][0]['horizontalCover']"
            />
            <div class="mask">
              <div
                class="maskText"
                :class="{
                  updateEnd: videoInfoData['MediaList'][0].updateStatus == 2,
                }"
              >
                {{ videoInfoData["MediaList"][0].updatedDesc }}
              </div>
            </div>
          </div>
          <div class="videoTxt">
            <p class="videoTxtTitle ellipsis">
              {{ videoInfoData["MediaList"][0].title }}
            </p>
            <div>
              <span
                v-for="(itemTag, indexTag) in videoInfoData['MediaList'][0][
                  'tagNames'
                ].slice(0, 3)"
                :key="indexTag"
                class="videoTxtTag"
                @click="
                  tagJump(videoInfoData['MediaList'][0], indexTag, itemTag)
                "
              >
                {{ itemTag }}
                <br v-if="(indexTag + 1) % 5 == 0" />
              </span>
            </div>
          </div>
        </div>
        <TransverselyTwoList
          :videoList="videoInfoData['MediaList'].slice(1, 5)"
        ></TransverselyTwoList>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * 横版视频列表（五）
 * ps:
 *      direction = 4   (一行大的,下面行都是两个一行)
 */
import ImgDecypt from "@/components/ImgDecypt";
import TransverselyTwoList from "@/components/VideoList/TransverselyTwoList";
import store from "@/store/index.js";
export default {
  name: "TransverselyFive",
  components: {
    ImgDecypt,
    TransverselyTwoList,
  },
  props: {
    videoInfoData: {
      type: Object,
    },
    tabNmae: String,
  },
  data() {
    return {
      // itemData:null
    };
  },
  mounted() {
    // if(this.videoInfoData != null){
    //     if(this.videoInfoData['MediaList'].length > 0){
    //         this.itemData = this.videoInfoData['MediaList'][0];
    //         this.videoInfoData['MediaList'].shift();
    //         if(this.videoInfoData['MediaList'].length > 4){
    //             this.videoInfoData['MediaList'] = this.videoInfoData['MediaList'].slice(0,4);
    //         }
    //     }
    // }
  },
  methods: {
    moreMore() {
      this.$router.push({
        path: "topicPage",
        query: {
          topicId: this.videoInfoData.id,
          zone: this.videoInfoData.zone,
          name: this.videoInfoData.name,
          format: 1,
          tabNmae: this.tabNmae,
        },
      });
    },
    jump(item) {
      let kind = item["kind"];
      if (kind != null) {
        switch (kind) {
          case 0:
          case 2:
          case 3:
          case 6:
            //跳转视频
            this.$router.push({
              path: "/videoDetailsPage",
              query: {
                id: item.id,
                kind: kind,
              },
            });
            break;
          case 4:
          case 1:
            store.commit("pageRefresh/SET_REFRESHFLAG", {
              name: "novelDetailsPage",
              flag: true,
            });
            //小说/漫画
            this.$router.push({
              path: "/novelDetailsPage",
              query: {
                id: item.id,
                kind: kind,
                price: item.price,
              },
            });
            break;
          case 5:
            //图集
            var list = JSON.stringify(this.videoInfoData["MediaList"]);
            //图集
            this.$router.push({
              path: "/portrayPage",
              query: {
                id: item.id,
                list: this.encodeBase64(list),
                phoneIndex: 0,
              },
            });
            break;
        }
      }
    },
    tagJump(item, index, itemTag) {
      let tagInfo = {
        id: item.tags[index],
        name: itemTag,
      };
      // console.log(this.$route.path)
      if (this.$route.path == "/tagList") {
        this.$router.replace({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
            t: Date.now(),
          },
        });
      } else {
        this.$router.push({
          path: "/tagList",
          query: {
            tagData: this.encodeBase64(encodeURI(JSON.stringify(tagInfo))),
            kind: item.kind,
          },
        });
      }
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
  },
};
</script>

<style lang="scss" scoped>
.transverselyFive {
  .videoItem {
    border-bottom: solid 1px rgb(230, 230, 230);
    padding-bottom: 12px;
    margin-top: 12px;

    .titleBar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .titleName {
        font-size: 18px;
        color: #000;
        font-weight: 500;
      }

      .titleDetails {
        font-size: 12px;
        color: #999999;
      }

      .moreRight {
        color: #000000;

        .moreRightTitle {
          font-size: 14px;
          margin-right: 6.1px;
        }

        img {
          width: 12px;
          height: 12px;
        }
      }
    }

    .videoInfo {
      margin-top: 9px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .videoInfoItemBag {
        width: 100%;
        margin-bottom: 12px;
        position: relative;

        .videoLabel {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          padding: 1px 4px;
          // border-radius: 3px;
          z-index: 1;
          top: 0px;
          left: 10px;
          text-wrap: none;
        }

        .vipLabel {
          color: rgb(102, 61, 0);
          background-image: linear-gradient(
            to right,
            rgb(255, 231, 168),
            rgb(242, 202, 91)
          );
        }

        .beanLabel {
          font-size: 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          color: rgb(255, 255, 255);
         background: rgba(255, 143, 76, 1);
         .Golden {
            width: 14px;
            height: 14px;
          }
        }

        .freeLabel {
          width: 30px;
          height: 16px;
          font-size: 10px;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          color: rgb(255, 255, 255);
          background-image: linear-gradient(
            to right,
            rgb(40, 249, 218),
            rgb(86, 143, 254)
          );
        }

        .previewImg {
          width: 100%;
          height: 197px;
          border-radius: 6px;
          background: rgba(27, 22, 76, 0.06);
          position: relative;

          // /deep/ .van-image{
          //     border-radius: 6px;
          // }

          // /deep/ .van-image__img{
          //     border-radius: 6px;
          // }
          .mask {
            position: absolute;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 40px;
            background-image: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0),
              rgba(0, 0, 0, 1)
            );
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
            font-size: 8px;
            .maskText {
              margin: 0 6px 6px 0;
              color: rgba(255, 241, 128, 1);
            }
            .updateEnd {
              color: #fff;
            }
            // background-image: linear-gradient(to right, rgb(255,231,168) , rgb(242,202,91));
          }
        }

        .videoTxt {
          margin-top: 6px;

          .videoTxtTitle {
            font-size: 12px;
            color: #000;
            font-weight: 400;
          }

          .videoTxtTag {
            font-size: 10px;
            color: #4a4a4a;
            margin-right: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
